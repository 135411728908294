import { User } from "Utils/types";

export interface Discussion {
    text: string;
    createdByDetails: User;
    createdBy: string;
    _id: string;
}
export enum FollowUpPriority {
    Low = 'Low',
    Medium = 'Medium',
    Urgent = 'Urgent',
}

export enum FollowupStatus {
    Resolved = 'Resolved',
    Open = 'Open',
}

export interface FollowUp {
    caseId: string;
    stageId: string;
    patientId: string;
    status: string;
    title: string;
    isRead: boolean;
    createdBy?: string;
    discussions: Array<Discussion>;
    endDate: string;
    responseRequested: boolean;
    startDate: string;
    priority: FollowUpPriority;
    recipientIds?: string[];
    recipients?: User[];
    createdByDetails?: User;
    _id: string;
}
export interface Note {
    caseId: string;
    stageId: string;
    stageName: string;
    phase: SurgeryPhases
    note?: string;
    createdAt?: string;
    createdById?: string;
    updatedAt?: string;
    _id: string;
    createdByDetails: User;
}

export enum NoteType {
    CASE = "case",
    STAGE = "stage"
}

export type CaseFile = {
    caseId: string;
    category: string;
    createdAt: string;
    createdBy: string;
    createdByDetails: User;
    fileName: string;
    s3Key: string;
    type: string;
    updatedAt: string;
    stageName: string;
    lastModifiedDate: string;
    _id: string;
};


export interface Stage {
    _id?: string;
    id: string;
    status: Status;
    name: string;
    caseId: string;
    frontEndId?: any
    surgicalPhase: SurgeryPhases;
    phase?: string;
    followupCount?: number;
    followup?: FollowUp[];
}

export enum Status {
    INPROGRESS = 'inprogress',
    COMPLETE = 'complete',
    ONHOLD = 'onhold',
    DEFAULT = 'default'
}

export enum SurgeryPhases {
    PRESURGERY = 'PreSurgery',
    SURGERY = 'Surgery'
}

export enum ComponentType {
    IMPLANT = 'implant',
    ABUTMENT = 'abutment',
    CYLINDER = 'cylinder',
    ATTACHMENT = 'attachment',
    SCREW = 'screw',
    SCREW_DRIVER = 'screwdriver',
    TORQUE_VALUE = 'torquevalue',
    EXTRACTION = 'extraction',
    TEMPORARY = 'temporary'
}

export interface Component {
    tooth: string;
    _id: string;
    type: ComponentType;
    name: string;
    manufacturer: string;
    lotNumber: string;
    referenceNumber: string;
}
export interface TimelineProp {
    handleStepIncrease?: () => void;
    handleStepDecrease?: () => void;
    readOnly?: boolean;
    timelineData?: any;
    isEdit?: boolean;
    caseId: string | undefined;
    patientId?: string;
}

export type ComponentDetails = {
    manufacturer: string[];
    lotNumber: string[];
    referenceNumber: string[];
    partName: string[];
};
import React from "react";
import { Box, Flex } from "@rebass/grid";
import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";

// Styles
import { CustomTag, Label } from "Assets/Styles/globalStyles";

// Types
import { FontSizes } from "Utils/enums";
import { S3_URL } from "config/constants";
import { colors } from "Assets/Styles/colors";

type Props = {
  url?: string;
  label: string;
  isPointer?: boolean;
  tag?: string;
  email?: string;
  followUpCount?: number;
};

const UserHead: React.FC<Props> = (props: Props) => {
  const { url = null, label, tag, isPointer, email, followUpCount } = props;

  return (
    <Flex alignItems="center">
      <Box>
        <Avatar src={url ? `${S3_URL}${url}` : null} icon={<UserOutlined />} />
      </Box>
      <Box marginLeft="5px">
        <Flex alignItems="center">
          <Label
            pointer={isPointer}
            color={colors.primaryText}
            fontSize={FontSizes.table}
            marginRight={5}
          >
            {label}
          </Label>
          {tag && <CustomTag type="secondary">{tag}</CustomTag>}
        </Flex>
        {email && (
          <Label
            pointer={isPointer}
            marginTop={-2}
            fontSize={FontSizes.floatingLabel}
            color={colors.secondaryText}
            lineToShow={1}
          >
            {email}
          </Label>
        )}
      </Box>
      {!!followUpCount && (
        <Box marginLeft="5px">
          <CustomTag type="error">
            {followUpCount} Conversation Request
          </CustomTag>
        </Box>
      )}
    </Flex>
  );
};

export default UserHead;
